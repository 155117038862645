<template>

  <DefaultCrudView
    @switch-inactive="switchInactive"
    @create-new="newUser"
    :breadcrumbsItems="breadcrumbs"
    showSearchBar
    @search-value-changed="searchValueChanged"
    :inactiveFilterValue="inactiveFilterValue"
    showInactiveFilter
    containerLarge
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="users"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :item-class="itemRowStyle"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.tenant_id="{ item }">
          {{ getTenantTitle(item.tenant_id) }}
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="editUser(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          
        </template>
      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" @submit="save">
        <template v-slot:content>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

    </template>
  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  >>>.inactive-row {
    color: #616161;
    opacity: 0.6;
  }
</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'
import TenantActions from '@/store/core/tenant/actions-types'
import UserActions from '@/store/core/user/actions-types'
import UserMutations from '@/store/core/user/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "User",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ]),

    ...mapState({
      fields: state => state.core.user.fields,
      tenants: state => state.core.tenant.tenants,
      groups: state => state.core.user.groups,
      selectedUser: state => state.core.user.selectedUser,
      inactiveFilterValue: state => state.core.user.inactiveFilterValue
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('users'),
          prependIcon: this.getIconForCoreSettingsItem('users'),
        },
      ]
    }
  },

  data() {
    return {
      loading: false,
      users: [],
      options: {
        sortBy: ['name'],
        sortDesc: [false],
      },
      itemsLength: 0,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Email', value: 'email'},
        {text: 'Active', value: 'active'},
        {text: 'Tenant', value: 'tenant_id'},
        {text: 'Groups', value: 'group_ids'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      searchValue: ""
    }
  },

  mounted() {
    this.loading = true

    this.[UserActions.GET_USER_GROUPS]()
      .then((groups) => {
        this.[UserMutations.SET_USER_GROUPS](groups.items)
      })

    this.[TenantActions.GET_ALL_TENANTS]()
      .then(() => {
        this.reloadData()
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('core/user', [
      UserActions.SAVE_USER,
      UserActions.GET_ALL_USERS,
      UserActions.DELETE_USER,
      UserActions.GET_USER_GROUPS
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapMutations('core/user', [
      UserMutations.SET_FIELD_TENANTS,
      UserMutations.SET_SELECTED_USER,
      UserMutations.SET_ROLE_FIELDS,
      UserMutations.SET_USER_GROUPS,
      UserMutations.SET_INACTIVE_FILTER_VAL
    ]),

    switchInactive() {
      this.[UserMutations.SET_INACTIVE_FILTER_VAL](!this.inactiveFilterValue)
      this.reloadData()
    },

    newUser() {
      this.[UserMutations.SET_SELECTED_USER](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this.[UserActions.SAVE_USER]()
        .then(() => {
          let message = this.selectedUser ? 'User edited' : 'User created'
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
          this.$refs.modal.closeModal()
          this.reloadData()
        })
    },

    reloadData() {
      let options = {}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      if (!this.inactiveFilterValue) {
        options = {
          ...options,
          'filter[active]' : "true"
        }
      }

      if (this.searchValue) {
        options = {
          ...options,
          'filter[term]' : this.searchValue
        }
      }

      this.loading = true
      this.[UserActions.GET_ALL_USERS](options)
        .then((result) => {
          this.users = [...result.items]

          this.itemsLength = result.total

          this.loading = false
        })
    },

    editUser(user) {
      this.[UserMutations.SET_SELECTED_USER](user)
      this.$refs.modal.openModal()
    },

    getModalTitle() {
      return this.selectedUser ? this.$t('Edit User #' + this.selectedUser.users_id) : this.$t('New User')
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    getGroupName(id) {
      let group = this.groups.find(f => f.user_group_id == id)

      return group ? group.group_title : ''
    },

    itemRowStyle(item) {
      return item.active ? '': 'inactive-row'
    },

    searchValueChanged(searchValue) {
      this.searchValue = searchValue      
      this.reloadData()
    }
  }
}

</script>